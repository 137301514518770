<template>
  <div class="back">
    <!-- <div class="title">合力官网{{ $parent.switchLanguage }}</div> -->
    <div class="title">
      <span>
        {{
          $parent.switchLanguage == "zh" ? "新品上新" : "NEW PRODUCT ON SALE"
        }}
        <div class="menu"></div>
      </span>
      <!-- <p>
        规模化生产、自动化与智能化的生产线、完整的产业链条持续的技术革新、数字化转型等多方面措施
      </p> -->
    </div>
    <div class="tag">
      <div
        class="tag_item"
        @click="clickTab(0)"
        :class="{ active: tabIndex === 0 }"
      >
        {{
          $parent.switchLanguage == "zh" ? "当季主打" : "Seasonal Highlights"
        }}
      </div>
      <div
        class="tag_item"
        @click="clickTab(1)"
        :class="{ active: tabIndex === 1 }"
      >
        {{
          $parent.switchLanguage == "zh" ? "智能产品" : "Intelligent Products"
        }}
      </div>
    </div>
    <div class="imgbox">
      <div v-if="tabIndex === 0">
        <!-- <img src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/newProduct%2Fimg1.jpg" alt="" /> -->
        <img src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/newProduct%2Fimg2.jpg" alt="" />
        <img src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/newProduct%2Fimg3.jpg" alt="" />
        <img src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/newProduct%2Fimg4.jpg" alt="" />
      </div>
      <div v-if="tabIndex === 1">
        <img src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/newProduct%2Fimg5.png" alt="" />
        <img src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/newProduct%2Fimg6.png" alt="" />
        <!-- <img src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/newProduct%2Fimg7.png" alt="" />
        <img src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/newProduct%2Fimg8.png" alt="" /> -->
        <img src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/newProduct%2Fimg9.png" alt="" />
        <img src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/newProduct%2Fimg10.png" alt="" />
        <img src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/newProduct%2Fimg11.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "newProduct",
  data() {
    return {
      switchLanguage: "",
      tabIndex: 0,
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    clickTab(type) {
      this.tabIndex = type;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  padding: 50px 0;
  span {
    position: relative;
  }
  .menu {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 90px;
    height: 6px;
    background: #96c33b;
    z-index: -1;
  }
  p {
    font-size: 14px;
    color: #666;
    margin-top: 10px;
    font-weight: normal;
  }
}
.tag {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  div:nth-child(1) {
    margin-right: 100px;
  }
  .tag_item {
    font-size: 16px;
    color: #9c9c9c;
    border: 1.3px solid #9c9c9c;
    padding: 8px 20px;
    border-radius: 5px;
  }
  .active {
    color: #96c33b;
    border: 1.3px solid #96c33b;
  }
}
.imgbox {
  width: 100%;
  // height: 1200px;
  padding: 30px 40px;
  img {
    // height: 50%;
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
